import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import './ChooseVehicleType.scss'
import { TickMark } from '../../../components/Icons';
const ChooseVehicleType = () => {
  const [isChecked, setIsChecked] = useState('product1');

  
  const handleSelectProduct = (productid) => {
    setIsChecked(productid); 
  };
  return (
    <>
      <div className='choose-vehicle-container'>
        <div className='container'>
          <div className='choose-vehicle-wrap'>
            <div className='choose-vehicle'>
              <Form.Label>Choose Your Vehicle Type</Form.Label>
              <Form.Select >
                <option>Choose</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </Form.Select>
            </div>
            <div className='choose-product-wrap'>
              <div className='choose-product-title'>Choose Our Product</div>
              <div className='product-slider'>
                <Swiper
                  spaceBetween={24}
                  slidesPerView={5}
                  breakpoints={{
                    300: {
                      slidesPerView: 1.5,
                    },
                    768: {
                      slidesPerView: 3,
                    },
                   
                    1199: {
                      slidesPerView: 5,
                    },
                  }}

                >
                  <SwiperSlide>
        
                  <div className={isChecked === 'product1' ? 'active product-box' : 'product-box'}>
                      <div className='choose-product-top'>
                        <div className='product-tag'>TT010</div>
                        <div className='product-select-wrap'>
                              <input id='product1' type='checkbox' value='product1' checked={isChecked === 'product1'}  onChange={()=>handleSelectProduct('product1')} />                        
                            <label htmlFor='product1' className='custom-checkbox'>
                            <TickMark />
                          </label>
                        </div>
                      </div>
                      <div className='choose-product-description'>
                        Self-Install OBDII Plug and Play tracker
                      </div>
                      <div className='choose-product-footer'>
                        <div className='product-price'>
                          $1,000
                        </div>
                        <div className='learn-more'>Learn More</div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                  <div className={isChecked === 'product2' ? 'active product-box' : 'product-box'}>
                    <div className='choose-product-top'>
                        <div className='product-tag'>TT010</div>
                        <div className='product-select-wrap'>
                        <input id='product2' type='checkbox' value='product2' checked={isChecked === 'product2'}  onChange={()=>handleSelectProduct('product2')} />                           
                            <label htmlFor='product2' className='custom-checkbox'>
                            <TickMark />
                          </label>
                        </div>
                      </div>
                      <div className='choose-product-description'>
                        Self-Install tracker connected to vehicle battery
                      </div>
                      <div className='choose-product-footer'>
                        <div className='product-price'>
                          $1,000
                        </div>
                        <div className='learn-more'>Learn More</div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                  <div className={isChecked === 'product3' ? 'active product-box' : 'product-box'}>
                      <div className='choose-product-top'>
                      <div className='choose-product-top'>
                        <div className='product-tag'>TT010</div>
                        <div className='product-select-wrap'>
                        <input id='product3' type='checkbox' value='product3' checked={isChecked === 'product3'}  onChange={()=>handleSelectProduct('product3')} />                          
                            <label htmlFor='product3' className='custom-checkbox'>
                            <TickMark />
                          </label>
                        </div>
                      </div>
                      </div>
                      <div className='choose-product-description'>
                        Hardwired tracker with dashcam
                      </div>
                      <div className='choose-product-footer'>
                        <div className='product-price'>
                          $1,000
                        </div>
                        <div className='learn-more'>Learn More</div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                  <div className={isChecked === 'product4' ? 'active product-box' : 'product-box'}>
                      <div className='choose-product-top'>
                      <div className='choose-product-top'>
                        <div className='product-tag'>TT010</div>
                        <div className='product-select-wrap'>
                        <input id='product4' type='checkbox' value='product4' checked={isChecked === 'product4'}  onChange={()=>handleSelectProduct('product4')} />                           
                            <label htmlFor='product4' className='custom-checkbox'>
                            <TickMark />
                          </label>
                        </div>
                      </div>
                      </div>
                      <div className='choose-product-description'>
                        Hardwired tracker with remote control options (CAN-Wires)
                      </div>
                      <div className='choose-product-footer'>
                        <div className='product-price'>
                          $1,000
                        </div>
                        <div className='learn-more'>Learn More</div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                  <div className={isChecked === 'product5' ? 'active product-box' : 'product-box'}>
                      <div className='choose-product-top'>
                      <div className='choose-product-top'>
                        <div className='product-tag'>TT010</div>
                        <div className='product-select-wrap'>
                        <input id='product5' type='checkbox' value='product5' checked={isChecked === 'product5'}  onChange={()=>handleSelectProduct('product5')} />                        
                            <label htmlFor='product5' className='custom-checkbox'>
                            <TickMark />
                          </label>
                        </div>
                      </div>
                      </div>
                      <div className='choose-product-description'>
                        Hardwired tracker (Most common)
                      </div>
                      <div className='choose-product-footer'>
                        <div className='product-price'>
                          $1,000
                        </div>
                        <div className='learn-more'>Learn More</div>
                      </div>
                    </div>
                  </SwiperSlide>

                </Swiper>
              </div>
            </div>
    
          </div>

        </div>
      </div>
    </>
  )
}

export default ChooseVehicleType