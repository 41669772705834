import React from 'react'
import HomeBanner from './HomeBanner/HomeBanner'
import OurServices from './OurServices/OurServices'
import AboutUs from './AboutUs/AboutUs'
import ReadyToTransform from '../../components/ReadyToTransform/ReadyToTransform'



const index = () => {
  return (
    <>
    <HomeBanner />
    <OurServices />
    <AboutUs/>
    <ReadyToTransform />
    </>
  )
}

export default index