import React, { useEffect } from 'react'
import ReadyToTransform from '../../components/ReadyToTransform/ReadyToTransform'
import ProductsBanner from './ProductsBanner/ProductsBanner'
import Products from './ProductsList/ProductsList'

const Index = () => {
    useEffect(() => {
        document.body.classList.add('remove-body-padding');
        document.getElementById('header').classList.add('header-bg-blue')
        return () => {
            document.body.classList.remove('remove-body-padding');
            document.getElementById('header').classList.remove('header-bg-blue')
        };
    }, []);
    return (
        <>
            <ProductsBanner />
            <Products />
            <ReadyToTransform />
        </>
    )
}

export default Index