import React, {useEffect} from 'react'
import PlansBanner from './PlansBanner/PlansBanner'
import Coverage from './Coverage/Coverage'
import PlansDetail from './PlansDetail/PlansDetail';
import ReadyToTransform from '../../components/ReadyToTransform/ReadyToTransform'

const Index = () => {
    useEffect(() => {
        document.body?.classList.add('remove-body-padding');
        document.getElementById('header')?.classList.add('header-bg-blue')
        return () => {
            document.body.classList.remove('remove-body-padding');
            document.getElementById('header')?.classList.remove('header-bg-blue')
        };
    }, []);
  return (
    <>
    <PlansBanner/>
    <PlansDetail />
    <Coverage/>
    <ReadyToTransform/>
    </>
  )
}

export default Index;