import React, { useEffect } from 'react'
import './Contactus.scss'
import Form from 'react-bootstrap/Form';
import { CallIcon, EmailIcon, LocationIcon } from '../../components/Icons';
import Button from 'react-bootstrap/Button';

const ContactUs = () => {
  useEffect(() => {
    document.body.classList.add('remove-body-padding');
    document.getElementById('header').classList.add('header-bg-blue')
    return () => {
      document.body.classList.remove('remove-body-padding');
      document.getElementById('header').classList.remove('header-bg-blue')
    };
  }, []);
  return (
    <>
      <div className='contact-wrapper-main'>
        <div className='container'>
          <div className='contact-wrapper'>
            <div className='contact-left'>
              <div className='contact-title-wrapper'>
                <div className='contact-title'>
                  Your Fleet's Success Starts Here
                </div>
                <div className='contact-description'>This could the gateway to a partnership that can redefine the future of your fleet management</div>
              </div>
              <div className='our-contact-wrap'>
                <div className='our-contact-title'>Our Contact</div>
                <div className='our-contacts'>
                  <div className='contact-tag'><LocationIcon /> Address</div>
                  <div className='contact-tag'><CallIcon /> Phone Number</div>
                  <div className='contact-tag'><EmailIcon /> Email</div>
                </div>
              </div>
            </div>
            <div className='contact-right'>
              <Form className='contact-form-wrap'>
                <div className="form-row" >
                  <Form.Label>Name</Form.Label>
                  <Form.Control type="text" placeholder="Enter your real name" />
                </div>
                <div className="form-row" >
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="text" placeholder="Enter your active email" />
                </div>
                <div className="form-row">
                  <Form.Label>Message</Form.Label>
                  <Form.Control as="textarea" placeholder='Write your message' />
                </div>
                <div className="form-row">
                <Button variant="primary">Submit</Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ContactUs