import React from 'react'
import HardwiredTracker from '../../../assets/images/products/hardwired-tracker.png'
import SelfInstallPlug from '../../../assets/images/products/self-install-plug.png'
import SelfInstallTracker from '../../../assets/images/products/self-install-tracker.png'
import AdvanceGPS from '../../../assets/images/products/advance-GPS.png'
import HardwiredTrackerRemoteControl from '../../../assets/images/products/hardwired-tracker-remote-control.png'
import './ProductsList.scss'
import { TickMark } from '../../../components/Icons';
import { useNavigate } from 'react-router-dom';
const ProductsList = () => {
  const navigate =useNavigate()
  return (
    <>
      <div className='product-list-wrapper'>

        <div className='container'>
          <div className='product-title-wrap'>Our Products</div>
          <div className='product-box-wrapper'>
            <div className='product-box'>
              <div className='product-tag'>
                <span>TT920</span>
              </div>
              <div className='product-img-wrap'>
                <img src={HardwiredTracker} alt='HardwiredTracker' />
              </div>
              <div className='product-detail-wrap'>
                <div className='product-detail-title-wrap'>
                  <div className='product-detail-title'>Hardwired tracker (Most common)</div>
                  <div className='product-detail-subtitle'>The best-selling hardwired anti-theft tracker with a back-up battery. (Installer required)</div>
                </div>
                <div className='product-list-wrap'>
                  <div className='product-list-row'>
                    <div className='product-icon-wrap'><TickMark /></div>
                    <div className='product-list'>Reliable hard-wired tracker for various applications</div>
                  </div>
                  <div className='product-list-row'>
                    <div className='product-icon-wrap'><TickMark /></div>
                    <div className='product-list'>Robust tracking capabilities</div>
                  </div>
                </div>
              </div>
              <div className='learn-more'>
                <span onClick={() =>navigate('/productdetail')}>Learn More</span>
              </div>
            </div>
            <div className='product-box'>
              <div className='product-tag'>
                <span>TT100</span>
              </div>
              <div className='product-img-wrap'>
                <img src={SelfInstallTracker} alt='HardwiredTracker' />
              </div>
              <div className='product-detail-wrap'>
                <div className='product-detail-title-wrap'>
                  <div className='product-detail-title'>Self-Install tracker connected to vehicle battery</div>
                  <div className='product-detail-subtitle'>A tracker with a built-in accelerometer & mounted directly on a car battery.</div>
                </div>
                <div className='product-list-wrap'>
                  <div className='product-list-row'>
                    <div className='product-icon-wrap'><TickMark /></div>
                    <div className='product-list'>Rugged tracker connects to a vehicle battery</div>
                  </div>
                  <div className='product-list-row'>
                    <div className='product-icon-wrap'><TickMark /></div>
                    <div className='product-list'>Continuous tracking for assets without ODB port</div>
                  </div>
                </div>
              </div>
              <div className='learn-more'>
                 <span onClick={() =>navigate('/productdetail')}>Learn More</span>
              </div>
            </div>
            <div className='product-box'>
              <div className='product-tag'>
                <span>TT010</span>
              </div>
              <div className='product-img-wrap'>
                <img src={SelfInstallPlug} alt='HardwiredTracker' />
              </div>
              <div className='product-detail-wrap'>
                <div className='product-detail-title-wrap'>
                  <div className='product-detail-title'>Self-Install OBDII Plug and Play tracker</div>
                  <div className='product-detail-subtitle'>A tracking device with a back-up battery, extremely easy to integrate via the OBD.</div>
                </div>
                <div className='product-list-wrap'>
                  <div className='product-list-row'>
                    <div className='product-icon-wrap'><TickMark /></div>
                    <div className='product-list'>Versatile tracker for vehicles</div>
                  </div>
                  <div className='product-list-row'>
                    <div className='product-icon-wrap'><TickMark /></div>
                    <div className='product-list'>Connects Easily to ODB port</div>
                  </div>
                  <div className='product-list-row'>
                    <div className='product-icon-wrap'><TickMark /></div>
                    <div className='product-list'>Real-time tracking and data insight</div>
                  </div>
                </div>
              </div>
              <div className='learn-more'>
                 <span onClick={() =>navigate('/productdetail')}>Learn More</span>
              </div>
            </div>
            <div className='product-box'>
              <div className='product-tag'>
                <span>TT150</span>
              </div>
              <div className='product-img-wrap'>
                <img src={AdvanceGPS} alt='HardwiredTracker' />
              </div>
              <div className='product-detail-wrap'>
                <div className='product-detail-title-wrap'>
                  <div className='product-detail-title'>Advanced GPS Tracker</div>
                  <div className='product-detail-subtitle'>An advanced GPS Tracker with the capability to provide real-time data readings from the CAN BUS of the vehicle. (Installer required)</div>
                </div>
                <div className='product-list-wrap'>
                  <div className='product-list-row'>
                    <div className='product-icon-wrap'><TickMark /></div>
                    <div className='product-list'>Hard-wired tracker with integrated dashcam</div>
                  </div>
                  <div className='product-list-row'>
                    <div className='product-icon-wrap'><TickMark /></div>
                    <div className='product-list'>Real-time tracking and visual evidence recording</div>
                  </div>
                </div>
              </div>
              <div className='learn-more'>
                 <span onClick={() =>navigate('/productdetail')}>Learn More</span>
              </div>
            </div>
            <div className='product-box'>
              <div className='product-tag'>
                <span>DMLO3</span>
              </div>
              <div className='product-img-wrap'>
                <img src={HardwiredTrackerRemoteControl} alt='HardwiredTracker' />
              </div>
              <div className='product-detail-wrap'>
                <div className='product-detail-title-wrap'>
                  <div className='product-detail-title'>Hardwired tracker with remote control options (CAN-Wires)</div>
                  <div className='product-detail-subtitle'>Ultra-rugged and long-life independent battery-powered GPS tracking device for long-term asset tracking.</div>
                </div>
                <div className='product-list-wrap'>
                  <div className='product-list-row'>
                    <div className='product-icon-wrap'><TickMark /></div>
                    <div className='product-list'>Hard-wired tracker with CAN data collection</div>
                  </div>
                  <div className='product-list-row'>
                    <div className='product-icon-wrap'><TickMark /></div>
                    <div className='product-list'>In-depth vehicle performance insights</div>
                  </div>
                </div>
              </div>
              <div className='learn-more'>
                 <span onClick={() =>navigate('/productdetail')}>Learn More</span>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </>
  )
}

export default ProductsList