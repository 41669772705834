import React from 'react'
import './PlansBanner.scss'
const PlansBanner = () => {
   
    return (
        <>
            <div className='plans-banner-wrapper'>
                <div className='plans-info-wrapper'>
                    <div className='plans-info-detail-wrap'>
                        <div className='plans-info-title'>Transparent Pricing Tailored for Your Fleet's <br /> Success</div>
                        <div className='plans-info-description'>
                            We believe in providing straightforward and customized pricing solutions to empower your journey toward operational excellence.
                        </div>
                        <div className='toggle-btn-wrapper'>
                            <div className="switches-container">
                                <input type="radio" id="switchMonthly" name="switchPlan" value="Monthly" defaultChecked  />
                                <input type="radio" id="switchAnnual" name="switchPlan" value="Annual" />
                                <label htmlFor="switchMonthly">Monthly</label>
                                <label htmlFor="switchAnnual">Annual</label>
                                <div className="switch-wrapper">
                                    <div className="switch">
                                        <div>Monthly</div>
                                        <div>Annual</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PlansBanner