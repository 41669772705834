import React from 'react'
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import './PlansDetail.scss'
import { ArrowRight, TickMark } from '../../../components/Icons';
const PlansDetail = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className='plans-details-wrapper'>
        <div className='container'>
          <div className='plans-detail-box-wrapper'>
            <div className='plans-detail-box'>
              <div className='plans-detail-content'>
                <div className='plans-title'>Free</div>
                <div className='plan-price-wrap'>
                  <div className='plan-price'>$0</div>
                  <div className='plan-duration'>/month</div>
                </div>
                <div className='feature-list-wrap'>
                  <div className='fl-row'>
                    <div className='fl-icon-wrap'>
                      <TickMark />
                    </div>
                    <div className='fl-text-wrap'>Features</div>
                  </div>
                  <div className='fl-row'>
                    <div className='fl-icon-wrap'>
                      <TickMark />
                    </div>
                    <div className='fl-text-wrap'>Features</div>
                  </div>
                  <div className='fl-row'>
                    <div className='fl-icon-wrap'>
                      <TickMark />
                    </div>
                    <div className='fl-text-wrap'>Features</div>
                  </div>
                </div>
              </div>
              <div className='plans-btn-wrap'>
                <Button variant='secondary' onClick={() => navigate('/signup')}>Sign Up <ArrowRight /></Button>
              </div>
            </div>
            <div className='plans-detail-box best-deal'>
              <div className='best-deal-wrap'>Best Deal</div>
              <div className='plans-detail-content'>
                <div className='plans-title'>Basic</div>
                <div className='plan-price-wrap'>
                  <div className='plan-price'>$19</div>
                  <div className='plan-duration'>/month</div>
                </div>
                <div className='feature-list-wrap'>
                  <div className='fl-row'>
                    <div className='fl-icon-wrap'>
                      <TickMark />
                    </div>
                    <div className='fl-text-wrap'>Features</div>
                  </div>
                  <div className='fl-row'>
                    <div className='fl-icon-wrap'>
                      <TickMark />
                    </div>
                    <div className='fl-text-wrap'>Features</div>
                  </div>
                  <div className='fl-row'>
                    <div className='fl-icon-wrap'>
                      <TickMark />
                    </div>
                    <div className='fl-text-wrap'>Features</div>
                  </div>
                  <div className='fl-row'>
                    <div className='fl-icon-wrap'>
                      <TickMark />
                    </div>
                    <div className='fl-text-wrap'>Features</div>
                  </div>
                  <div className='fl-row'>
                    <div className='fl-icon-wrap'>
                      <TickMark />
                    </div>
                    <div className='fl-text-wrap'>Features</div>
                  </div>
                  <div className='fl-row'>
                    <div className='fl-icon-wrap'>
                      <TickMark />
                    </div>
                    <div className='fl-text-wrap'>Features</div>
                  </div>
                </div>
              </div>
              <div className='plans-btn-wrap'>
                <Button variant='primary' onClick={() => navigate('/payment')}>Subscribe<ArrowRight /></Button>
              </div>
            </div>
            <div className='plans-detail-box'>
              <div className='plans-detail-content'>
                <div className='plans-title'>Enterprise</div>
                <div className='plan-price-wrap'>
                  <div className='plan-price'>$39</div>
                  <div className='plan-duration'>/month</div>
                </div>
                <div className='feature-list-wrap'>
                  <div className='fl-row'>
                    <div className='fl-icon-wrap'>
                      <TickMark />
                    </div>
                    <div className='fl-text-wrap'>Features</div>
                  </div>
                  <div className='fl-row'>
                    <div className='fl-icon-wrap'>
                      <TickMark />
                    </div>
                    <div className='fl-text-wrap'>Features</div>
                  </div>
                  <div className='fl-row'>
                    <div className='fl-icon-wrap'>
                      <TickMark />
                    </div>
                    <div className='fl-text-wrap'>Features</div>
                  </div>
                  <div className='fl-row'>
                    <div className='fl-icon-wrap'>
                      <TickMark />
                    </div>
                    <div className='fl-text-wrap'>Features</div>
                  </div>
                  <div className='fl-row'>
                    <div className='fl-icon-wrap'>
                      <TickMark />
                    </div>
                    <div className='fl-text-wrap'>Features</div>
                  </div>
                </div>
              </div>
              <div className='plans-btn-wrap'>
              <Button variant='secondary' onClick={() => navigate('/payment')}>Subscribe<ArrowRight /></Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PlansDetail