import React from 'react'
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import './ReadyToTransform.scss'

const ReadyToTransform = () => {
  const navigate = useNavigate();
  return (
    <>
    <div className='ready-transform-wrapper'>
        <div className='rt-content-wrap'>
            <div className='rt-heading-wrapper'>
                <div className='rt-heading'>Ready to Transform Your Fleet Experience? Take the First Step!</div>
                <div className='rt-description'>
                We invite you to seize control of your fleet's destiny with a simple click. Embrace innovation, enhance efficiency, and elevate your operations to new heights.
                </div>
            </div>
            <div className='rt-order-wrap'>
            <Button variant="light" onClick={() => navigate('/order')}>Order Now</Button>
            </div>
            <div className='rt-link-wrap'>
                <div className='rt-link-title'>Need for commercial?</div>
                <div className='rt-link'><span onClick={() => navigate('/contactus')}>Contact</span></div>
            </div>
        </div>
    </div>
    </>
  )
}

export default ReadyToTransform