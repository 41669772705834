import React, {useState} from 'react'
import { useNavigate } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import LanfFlag from '../../assets/images/lang-flag.png'
import Button from 'react-bootstrap/Button';
import './Login.scss'
import { EyeIcon, TickMark } from '../../components/Icons';
const Login = () => {
    const navigate = useNavigate();
    const [isChecked, setIsChecked] = useState(true);

    const handleCheckboxChange = (event) => {
      setIsChecked(event.target.checked);
    };
    return (
        <>
            <div className='auth-container'>
                <div className='auth-wrapper'>
                    <div className='auth-top'>
                        <div className='auth-title-wrap'>
                            <div className='auth-title'>Welcome back!</div>
                            <div className='auth-decription'>Login to Continue</div>
                        </div>
                        <div className='lang-wrapper-main'>
                            <div className='lang-wrap'>
                                <div className='flag-wraper'><img src={LanfFlag} alt='Flag' /></div>
                                <div className='lang-name-wrap'>English</div>
                            </div>
                        </div>
                    </div>
                    <Form className='login-form-wrap'>
                        <div className='login-input-container'>
                            <div className='form-row'>
                                <Form.Label>User Name</Form.Label>
                                <Form.Control type="text" placeholder="Enter your Username" />
                            </div>
                            <div className='form-row'>
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" placeholder="Enter your Password" />
                                <div className='icon-wrap'><EyeIcon/></div>
                            </div>
                        </div>
                        <div className='remember-wrap'>
                            <div className="custom-checkbox">
                                <input type="checkbox" id="remember"checked={isChecked} onChange={handleCheckboxChange}/>
                                <i><TickMark /></i>
                                <label htmlFor="remember">Remember me</label>
                            </div>
                            <div className='forgot-wrap'>
                                Forget Password?
                            </div>
                        </div>
                        <div className='btn-wrap'>
                            <Button type='submit' variant='primary'>Login</Button>
                        </div>
                        <div className='have-an-account-wrapper'>Don’t have an account? <span onClick={() => navigate('/signup')}>Sign up</span></div>
                    </Form>
                </div>

            </div>
        </>
    )
}

export default Login