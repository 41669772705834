import React from 'react'
import { useNavigate} from "react-router-dom";
import Button from 'react-bootstrap/Button';
import './Purchase.scss'
import CarImg from '../../../assets/images/order-car.png'
const Purchase = () => {
  const navigate = useNavigate();
  return (
    <>
      
       <div className='purchase-container service'>
          <div className='purchase-wrapper'>
            <div><Button variant="primary" onClick={() => navigate('/Payment')}>Purchase Now</Button></div>
            <div className='purchase-link'>Need for commercial customers? <span onClick={() => navigate('/contactus')}>Contact Us</span></div>
          </div>
          <div className='car-img-wrap'>
          <img src={CarImg} alt='Car' />
          </div>
          </div>
      
    </>
  )
}

export default Purchase