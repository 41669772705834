import React from 'react'
import PublicLayout from '../components/Layouts/Public'
import Home from '../pages/Home/'
import Products from '../pages/Products'
import Plans from '../pages/Plans'
import Order from '../pages/Order'
import Service from '../pages/Service'
import ContactUs from '../pages/Contactus/Contactus'
import AuthLayout from '../components/Layouts/Auth'
import Login from '../pages/Login/Login'
import SignUp from '../pages/SignUp/SignUp'
import PaymentLayout from '../components/Layouts/PaymentLayout'
import Payment from '../pages/Payment/Payment'
import ProductDetail from '../pages/ProductDetail/ProductDetail'

const PublicRoutes= [
    {
        path: '/',
        exact: true,
        layout: PublicLayout,
        component: <Home />
    },
    {
        path: '/products',
        exact: true,
        layout: PublicLayout,
        component: <Products/>
    },
    
    {
        path: '/productdetail',
        exact: true,
        layout: PublicLayout,
        component: <ProductDetail/>
    },
    {
        path: '/plans',
        exact: true,
        layout: PublicLayout,
        component: <Plans/>
    },
    {
        path: '/order',
        exact: true,
        layout: PublicLayout,
        component: <Order/>
    },
    {
        path: '/services',
        exact: true,
        layout: PublicLayout,
        component: <Service/>
    },
    {
        path: '/contactus',
        exact: true,
        layout: PublicLayout,
        component: <ContactUs/>
    },
    {
        path: '/login',
        exact: true,
        layout: AuthLayout,
        component: <Login/>
    },
    {
        path: '/signup',
        exact: true,
        layout: AuthLayout,
        component: <SignUp/>
    },
    {
        path: '/payment',
        exact: true,
        layout: PaymentLayout,
        component: <Payment/>
    }

]

export default PublicRoutes