import React from 'react'
import { Link, useNavigate } from "react-router-dom";
import Logo from '../../assets/images/logo.svg'
import './footer.scss'
const Footer = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className='container'>
        <footer>
          <div className='footer-wrapper'>
            <div className='footer-logo-wrapper'>
              <div className='footer-logo'> <img src={Logo} alt='NovoTrack' /></div>
              <div className='legal-info'>
                2024 &copy; All Rights Reserved
              </div>
            </div>
            <div className='footer-nav-wrapper'>
              <div className='footer-nav-wrap'>
                <h3 className='footer-nav-title'>Company</h3>
                <ul className='footer-nav-list'>
                  <li ><span onClick={() => navigate('/products')}>Features</span></li>
                  <li><span onClick={() => navigate('/plans')}>Pricing</span></li>
                  <li><span onClick={() => navigate('/')}>About Us</span></li>
                  <li ><span onClick={() => navigate('/contactus')}>Contact</span></li>

                </ul>
              </div>
              <div className='footer-nav-wrap'>
                <h3 className='footer-nav-title'>Contact</h3>
                <ul className='footer-nav-list'>
                  <li><Link to='https://www.instagram.com/' target='_blank'>Instagram</Link></li>
                  <li><Link to='https://twitter.com/' target='_blank'>X</Link></li>
                  <li><Link to='http://facbook.com/' target='_blank'>Facebook</Link></li>
                  <li><Link to='https://linkedin.com/' target='_blank'>LinkedIn</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  )
}

export default Footer