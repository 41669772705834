import React, { useEffect } from 'react'
import HardWiredTrackerImg from '../../assets/images/hardwired-tracker.png'
import KeyFeature1 from '../../assets/images/key-feature-1.png'
import KeyFeature2 from '../../assets/images/key-feature-2.png'
import { BackArrowIcon, CarIcon, NavigationIcon } from '../../components/Icons';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './ProductDetail.scss'

const ProductDetail = () => {
  const navigate = useNavigate();
  useEffect(() => {
    document.body.classList.add('remove-body-padding');
    document.getElementById('header').classList.add('header-bg-blue')
    return () => {
      document.body.classList.remove('remove-body-padding');
      document.getElementById('header').classList.remove('header-bg-blue')
    };
  }, []);
  return (
    <>

      <div className='product-detail-top-wrapper'>
        <div className='container'>
          <div className='product-detail-top'>
            <div className='product-detail-left'>
              <div className='btn-wrap back-btn'>
                <Button variant='light' onClick={() => navigate(-1)}><BackArrowIcon /> Back</Button>
              </div>
              <div className='product-detail'>
                <div className='productid'>TT920</div>
                <div className='product-name'>Hardwired tracker (Most common)</div>
                <div className='product-description'>The best-selling hardwired anti-theft tracker with a back-up battery. <br />(Installer required)</div>
              </div>
              <div>
                <Button variant='primary'>Order Now</Button>
              </div>
            </div>
            <div className='product-img-wrap'>
              <img src={HardWiredTrackerImg} alt='HardWired Tracker' />
            </div>
          </div>
        </div>
      </div>
      <div className='key-feature-container'>
        <div className='container'>
          <div className='key-feature-wrapper'>
            <div className='kf-section-title'>Key Features</div>
            <div className='kf-row'>
              <div className='kf-content-wrap order-1 order-lg-0'>
                <div className='kf-title'>Reliable hard-wired tracker for various applications</div>
                <div className='kf-description'>Description Lorem ipsum dolor sit amet consectetur. Varius malesuada quis sit morbi urna. Scelerisque viverra mi sed sed. Viverra amet cursus facilisi vel. Eget vulputate ultrices sit risus pellentesque massa porttitor.</div>
              </div>
              <div className='kf-img-wrap order-0 order-lg-1'>
                <div className='fearure-icon right'><CarIcon /></div>
                <img src={KeyFeature1} alt='Key Feature' />
              </div>
            </div>
            <div className='kf-row'>
              <div className='kf-img-wrap  '>
                <div className='fearure-icon'>
                  <NavigationIcon />
                </div>
                <img src={KeyFeature2} alt='Key Feature' />
              </div>
              <div className='kf-content-wrap '>
                <div className='kf-title'>Robust tracking capabilities</div>
                <div className='kf-description'>Description Lorem ipsum dolor sit amet consectetur. Varius malesuada quis sit morbi urna. Scelerisque viverra mi sed sed. Viverra amet cursus facilisi vel. Eget vulputate ultrices sit risus pellentesque massa porttitor.</div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default ProductDetail