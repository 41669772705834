import React, {  useState } from 'react'
import './ChooseSubscription.scss'
import { TickMark } from '../../../components/Icons'

const ChooseSubscription = () => {
  const [isSubscription, setSbuscription] = useState('subscription1');

  const handleSubscription = (subcriptionid) => {
    setSbuscription(subcriptionid)
  }
  return (
    <>
      <div className='subscription-wrapper-main'>
        <div className='container'>
          <div className='subscription-wrapper'>
            <div className='subscription-title'>Choose subscription</div>
            <div className='subscription-box-wrapper'>
              <div className={isSubscription === 'subscription1' ? 'subscription-box active' : 'subscription-box'}>
                <div className='choose-subscription-top'>
                  <div className='choose-subscription-top'>
                    <div className='subscription-tag'>Subscription 1</div>
                    <div className='subscription-select-wrap'>
                      <input id='subscription1' type='radio'  name='subscription' checked={isSubscription === 'subscription1'} onChange={()=>handleSubscription('subscription1')}/>
                      <label htmlFor='subscription1' className='custom-checkbox'>
                        <TickMark />
                      </label>
                    </div>
                  </div>
                </div>
                <div className='choose-subscription-description'>
                  Title Subscription
                </div>
                <div className='choose-subscription-footer'>
                  <div className='subscription-price'>
                    $1,000
                  </div>
                  <div className='learn-more'>Learn More</div>
                </div>
              </div>
              <div className={isSubscription === 'subscription2' ? 'subscription-box active' : 'subscription-box'}>
                <div className='choose-subscription-top'>
                  <div className='choose-subscription-top'>
                    <div className='subscription-tag'>Subscription 2</div>
                    <div className='subscription-select-wrap'>
                      <input id='subscription2' name='subscription' type='radio' checked={isSubscription === 'subscription2'} onChange={()=>handleSubscription('subscription2')} />
                      <label htmlFor='subscription2' className='custom-checkbox'>
                        <TickMark />
                      </label>
                    </div>
                  </div>
                </div>
                <div className='choose-subscription-description'>
                  Title Subscription
                </div>
                <div className='choose-subscription-footer'>
                  <div className='subscription-price'>
                    $1,000
                  </div>
                  <div className='learn-more'>Learn More</div>
                </div>
              </div>
              <div className={isSubscription === 'subscription3' ? 'subscription-box active' : 'subscription-box'}>
                <div className='choose-subscription-top'>
                  <div className='choose-subscription-top'>
                    <div className='subscription-tag'>Subscription 3</div>
                    <div className='subscription-select-wrap'>
                      <input id='subscription3'  name='subscription' checked={isSubscription === 'subscription3'} type='radio' onChange={()=>handleSubscription('subscription3')} />
                      <label htmlFor='subscription3' className='custom-checkbox'>
                        <TickMark />
                      </label>
                    </div>
                  </div>
                </div>
                <div className='choose-subscription-description'>
                  Title Subscription
                </div>
                <div className='choose-subscription-footer'>
                  <div className='subscription-price'>
                    $1,000
                  </div>
                  <div className='learn-more'>Learn More</div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </>
  )
}

export default ChooseSubscription