import React from 'react'
import { TickMark } from '../../../components/Icons'
import JustTrack from '../../../assets/images/just-track.png'
import VehicleTraking from '../../../assets/images/vehicle-tracking.png'
import TrackingAlert from '../../../assets/images/tracking-alert.png'
import './OurServices.scss'
const OurServices = () => {
    return (
        <>
            <div className='our-services-wrapper'>
                <div className='container'>
                    <div className='os-content-container'>
                        <div className='os-heading-wrap'>
                            <div className='os-heading'>Our Services</div>
                            <div className='os-description'>Discover the power of these features and transform the way you manage your vehicles</div>
                        </div>
                        <div className='os-container'>
                            <div className='os-row'>
                                <div className='os-img-wrap'>
                                    <img src={JustTrack} alt='Just Track' />
                                </div>
                                <div className='os-wrapper'>
                                    <div className='os-tag'>
                                        <span>Service 1</span>
                                    </div>
                                    <div className='os-info-wrap'>
                                        <div className='os-title-wrap'>
                                            Just Track<br />
                                            <span>Vehicle Tracking</span>
                                        </div>
                                        <div className='os-list-wrap'>
                                            <div className='os-list-row'>
                                                <div className='os-list-icon'><TickMark /></div>
                                                <div className='os-list-content'>Real-time vehicle tracking service</div>
                                            </div>
                                            <div className='os-list-row'>
                                                <div className='os-list-icon'><TickMark /></div>
                                                <div className='os-list-content'>Live location data for accurate monitoring</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='os-row vehicle-alert'>
                                <div className='os-img-wrap'>
                                    <img src={VehicleTraking} alt='Just Track' />
                                </div>
                                <div className='os-wrapper'>
                                    <div className='os-tag'>
                                        <span>Service 2</span>
                                    </div>
                                    <div className='os-info-wrap'>
                                        <div className='os-title-wrap'>
                                            Service 1 + Alerts
                                            <br />
                                            <span>Vehicle Tracking & Alerts</span>
                                        </div>
                                        <div className='os-list-wrap'>
                                            <div className='os-list-row'>
                                                <div className='os-list-icon'><TickMark /></div>
                                                <div className='os-list-content'>Vehicle tracking with customizable alerts</div>
                                            </div>
                                            <div className='os-list-row'>
                                                <div className='os-list-icon'><TickMark /></div>
                                                <div className='os-list-content'>Notifications for speeding, harsh braking, etc.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='os-row'>
                                <div className='os-img-wrap'>
                                    <img src={TrackingAlert} alt='Vehicle Traking' />
                                </div>
                                <div className='os-wrapper'>
                                    <div className='os-tag'>
                                        <span>Service 3</span>
                                    </div>
                                    <div className='os-info-wrap'>
                                        <div className='os-title-wrap'>
                                            Service 2 + 24/7 Hotline<br />
                                            <span>Tracking, Alerts, and Stolen Vehicle Recovery</span>
                                        </div>
                                        <div className='os-list-wrap'>
                                            <div className='os-list-row'>
                                                <div className='os-list-icon'><TickMark /></div>
                                                <div className='os-list-content'>Real-time vehicle tracking service</div>
                                            </div>
                                            <div className='os-list-row'>
                                                <div className='os-list-icon'><TickMark /></div>
                                                <div className='os-list-content'>Live location data for accurate monitoring</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OurServices