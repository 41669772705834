import React from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import './ProductsBanner.scss'

const ProductsBanner = () => {

    return (
        <>
            <div className='product-banner-wrapper'>
                <div className='product-info-wrapper'>
                    <div className='product-info-detail-wrap'>
                        <div className='product-info-title'>We provides vehicle monitoring, fleet <br /> management, driving behavior and compliance</div>
                        <div className='product-info-description'>

                            Elevate your fleet efficiency with our comprehensive services, including real-time vehicle monitoring, advanced fleet management, detailed insights into driving behavior, and seamless compliance tracking.
                        </div>
                    </div>
                    <div className='product-serch-wrap'>
                        <div className='product-search'>
                           
                                <Form.Control type="text" placeholder="Search Our Product/Services" />
                            
                        </div>
                        <div className='btn-wrap'><Button variant='primary' type='submit'>Search</Button></div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default ProductsBanner