import React from 'react'
import { Scrollbars } from 'react-custom-scrollbars-2';
import EuropeBasicMap from '../../../assets/images/europe-basic.png'
import EuropeExtendedMap from '../../../assets/images/europe-extended.png'
import Global from '../../../assets/images/global.png'
import Flag from '../../../assets/images/flags/Flags.png'
import './Coverage.scss'

const Coverage = () => {
  return (
    <>
     <div className='coverage-container-wrap'>
        <div className='container'>
          <div className='coverage-content-wrapper'>
            <div className='coverage-section-title'>Coverage</div>
            <div className='cc-container'>
              <div className='cc-map-wraper'>
                <img src={EuropeBasicMap} alt='World Map' />
              </div>
              <div className='cc-country-wrap'>
                <div className='cc-country-title'>Europe Basic</div>
                <div className="country-list-wrap">
                  <div className='country-list-title'>
                    Country List
                  </div>
                  <Scrollbars autoHide style={{ height: '100%' }}>
                    <div className='country-list'>

                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                    </div>
                  </Scrollbars>
                </div>
              </div>
            </div>
            <div className='cc-container'>
              <div className='cc-map-wraper'>
                <img src={EuropeExtendedMap} alt='World Map' />
              </div>
              <div className='cc-country-wrap'>
                <div className='cc-country-title'>Europe Extended</div>
                <div className="country-list-wrap">
                  <div className='country-list-title'>
                    Country List
                  </div>
                  <Scrollbars autoHide style={{ height: '100%' }}>
                    <div className='country-list'>

                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                    </div>
                  </Scrollbars>
                </div>
              </div>
            </div>
            <div className='cc-container'>
              <div className='cc-map-wraper'>
                <img src={Global} alt='World Map' />
              </div>
              <div className='cc-country-wrap'>
                <div className='cc-country-title'>Global</div>
                <div className="country-list-wrap">
                  <div className='country-list-title'>
                    Country List
                  </div>
                  <Scrollbars autoHide style={{ height: '100%' }}>
                    <div className='country-list'>

                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                    </div>
                  </Scrollbars>
                </div>
              </div>
            </div>
            <div className='cc-container'>
              <div className='cc-map-wraper'>
                <img src={Global} alt='World Map' />
              </div>
              <div className='cc-country-wrap'>
                <div className='cc-country-title'>Global Extended</div>
                <div className="country-list-wrap">
                  <div className='country-list-title'>
                    Country List
                  </div>
                  <Scrollbars autoHide style={{ height: '100%' }}>
                    <div className='country-list'>

                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                      <div className='country-list-row'>
                        <div className='country-icon'>
                          <img src={Flag} alt='Flag' />
                        </div>
                        <div className='country-name'>United Kingdom</div>
                      </div>
                    </div>
                  </Scrollbars>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>


    </>
  )
}

export default Coverage
