import React from 'react'
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import FrameImg from '../../../assets/images/frame.png'
import FrameImgTab from '../../../assets/images/frame-tab.png'
import CarImg from '../../../assets/images/home-car.png'
import CarImgTab from '../../../assets/images/home-car-tab.png'
import './HomeBanner.scss'

const HomeBanner = () => {
    const navigate = useNavigate()
    return (
        <>
            <div className='home-main-wrapper'>
                <div className='home-info-wrapper'>
                    <div className='home-info-detail-wrap'>
                        <div className='home-info-title'>We provides vehicle monitoring, fleet <br /> management, driving behavior and compliance</div>
                        <div className='home-info-description'>

                            Elevate your fleet efficiency with our comprehensive services, including real-time vehicle monitoring, advanced fleet management, detailed insights into driving behavior, and seamless compliance tracking.
                        </div>
                    </div>
                    <div className='home-btn-wrap'>
                        <Button variant='primary' onClick={() => navigate('/signup')}>Register Now</Button>
                    </div>
                </div>
                <div className='home-img-container'>
                    <div className='car-container'>
                        <picture>
                            <source media="(max-width:991px)" srcSet={CarImgTab} />
                            <img src={CarImg} alt='Car' />
                        </picture>
                    </div>
                    <div className='frame-container'>
                        <picture>
                            <source media="(max-width:991px)" srcSet={FrameImgTab} />
                            <img src={FrameImg} alt='Frame' />
                        </picture>

                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeBanner