import React, {useState} from 'react'
import { Dropdown } from 'react-bootstrap';
import { useNavigate, useLocation, useMatch } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import { DownArrow, Hamburger } from '../Icons';
import Logo from '../../assets/images/logo.svg'
import './header.scss';

const Header = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const [isNavOpen, setIsNavOpen] = useState(false);
  const handleHamburger = () => {
    setIsNavOpen(!isNavOpen);
  };
  const handleNavigate = (path) =>{
    navigate(path)
    setIsNavOpen(false);
  }
  return (
    <>
    <header id='header'>
      <div className='container'>
        <div className='header-wrapper'>
          <div className="logo" onClick={() => navigate('/')} >
            <img src={Logo} alt='NovoTrack' />
          </div>
          <div className="header-nav-wrap">
           
              <ul className={`nav ${isNavOpen ? 'open' : ''}`}>
                <li onClick={() => handleNavigate('/')} className={useMatch('/') ? "active" : ""}>Home</li>
                <li onClick={() => handleNavigate('/products')} className={location.pathname.startsWith("/products") ? "active" : ""}>Products</li>
                <li onClick={() => handleNavigate('/plans')} className={location.pathname.startsWith("/plans") ? "active" : ""}>Plans</li>
                <li onClick={() => handleNavigate('/services')} className={location.pathname.startsWith("/services") ? "active" : ""}>Services</li>
                <li onClick={() => handleNavigate('/order')} className={location.pathname.startsWith("/order") ? "active" : ""}>
                  <Dropdown>
                    <Dropdown.Toggle id="service-nav" >
                      Order <DownArrow />
                    </Dropdown.Toggle >
                    <Dropdown.Menu>
                      <Dropdown.Item href="">Order 1</Dropdown.Item>
                      <Dropdown.Item href="">Order 2</Dropdown.Item>
                      <Dropdown.Item href="">Order 3</Dropdown.Item>
                      <Dropdown.Item href="">Order 4</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
                <li onClick={() => handleNavigate('/contactus')} className={location.pathname.startsWith("/contactus") ? "active" : ""}>Contact Us</li>
                <li className='login-btn-wrap'> <Button variant='outline-secondary' onClick={() => handleNavigate('/login')}>Login</Button></li>
              </ul>
            
           
              <div className='hamburger' onClick={()=>handleHamburger()}><Hamburger /></div>
            
          </div>
          </div>
      </div>
      </header>
    </>
  )
}

export default Header