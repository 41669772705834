import React from 'react'
import FrameImg from '../../../assets/images/frame.png'
import CarImg from '../../../assets/images/about-car.png'
import './AboutUs.scss'

const AboutUs = () => {
    return (
        <>
            <div className='aboutus-wrapper'>
                <div className='container'>
                    <div className='about-content-wrapper'>
                        <div className='about-section-title'>About Us</div>
                        <div className='about-content'>
                            <div className='about-title'>Novotrack is a vehicle telematics company that provides vehicle monitoring, fleet management, driving behavior and compliance.</div>
                            <div className='about-description'>
                                We are driven by a mission to redefine vehicle telematics, providing cutting-edge solutions that empower businesses to thrive in a connected world. From enhancing fleet efficiency to promoting responsible driving behavior, every aspect of our work is guided by the desire to make a positive impact.
                            </div>
                        </div>
                    </div>
                </div>
                <div className='about-bottom-wrap'>
                    <div className='container'>
                        <div className='about-bottom-section'>
                            <div className='about-frame-wrap'>
                                <img src={FrameImg} alt='Frame' />
                            </div>

                            <div className='car-img-wrap'>
                                <img src={CarImg} alt='Frame' />
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </>
    )
}

export default AboutUs