import React from 'react'
import { Scrollbars } from 'react-custom-scrollbars-2';
import Form from 'react-bootstrap/Form';
import EuropeBasicMap from '../../../assets/images/europe-basic.png'
import Flag from '../../../assets/images/flags/Flags.png'
import './ServiceCoverage.scss'
const ServiceCoverage = () => {
  return (
    <>
     <div className='container'>
        <div className='cc-container service'>
          <div className='cc-map-wraper'>
            <img src={EuropeBasicMap} alt='World Map' />
          </div>
          <div className='cc-country-wrap'>
            <div className='cc-country-title'>Coverage</div>
            <div class="country-list-wrap">
              <div className='country-list-title'>
                <Form.Select >
                  <option>Europe Basic</option>
                  <option value="1">Europe Extended</option>
                  <option value="2">Global</option>
                  <option value="3">Global Extended</option>
                </Form.Select>
              </div>
              <Scrollbars autoHide style={{ height: '100%' }}>
                <div className='country-list'>

                  <div className='country-list-row'>
                    <div className='country-icon'>
                      <img src={Flag} alt='Flag' />
                    </div>
                    <div className='country-name'>United Kingdom</div>
                  </div>
                  <div className='country-list-row'>
                    <div className='country-icon'>
                      <img src={Flag} alt='Flag' />
                    </div>
                    <div className='country-name'>United Kingdom</div>
                  </div>
                  <div className='country-list-row'>
                    <div className='country-icon'>
                      <img src={Flag} alt='Flag' />
                    </div>
                    <div className='country-name'>United Kingdom</div>
                  </div>
                  <div className='country-list-row'>
                    <div className='country-icon'>
                      <img src={Flag} alt='Flag' />
                    </div>
                    <div className='country-name'>United Kingdom</div>
                  </div>
                  <div className='country-list-row'>
                    <div className='country-icon'>
                      <img src={Flag} alt='Flag' />
                    </div>
                    <div className='country-name'>United Kingdom</div>
                  </div>
                  <div className='country-list-row'>
                    <div className='country-icon'>
                      <img src={Flag} alt='Flag' />
                    </div>
                    <div className='country-name'>United Kingdom</div>
                  </div>
                  <div className='country-list-row'>
                    <div className='country-icon'>
                      <img src={Flag} alt='Flag' />
                    </div>
                    <div className='country-name'>United Kingdom</div>
                  </div>
                  <div className='country-list-row'>
                    <div className='country-icon'>
                      <img src={Flag} alt='Flag' />
                    </div>
                    <div className='country-name'>United Kingdom</div>
                  </div>
                  <div className='country-list-row'>
                    <div className='country-icon'>
                      <img src={Flag} alt='Flag' />
                    </div>
                    <div className='country-name'>United Kingdom</div>
                  </div>
                  <div className='country-list-row'>
                    <div className='country-icon'>
                      <img src={Flag} alt='Flag' />
                    </div>
                    <div className='country-name'>United Kingdom</div>
                  </div>
                  <div className='country-list-row'>
                    <div className='country-icon'>
                      <img src={Flag} alt='Flag' />
                    </div>
                    <div className='country-name'>United Kingdom</div>
                  </div>
                  <div className='country-list-row'>
                    <div className='country-icon'>
                      <img src={Flag} alt='Flag' />
                    </div>
                    <div className='country-name'>United Kingdom</div>
                  </div>
                  <div className='country-list-row'>
                    <div className='country-icon'>
                      <img src={Flag} alt='Flag' />
                    </div>
                    <div className='country-name'>United Kingdom</div>
                  </div>
                  <div className='country-list-row'>
                    <div className='country-icon'>
                      <img src={Flag} alt='Flag' />
                    </div>
                    <div className='country-name'>United Kingdom</div>
                  </div>
                </div>
              </Scrollbars>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ServiceCoverage
