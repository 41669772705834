import React from 'react'
import CarImg from '../../../assets/images/order-car.png'
import './OrderBanner.scss'
const OrderBanner = () => {
  return (
    <div className='order-banner-container'>
      <div className='order-banner'>
     <div className='ob-content-wrap'>
     <div className='order-container'>
       <div className='ob-title-wrapper'>
        <div className='ob-title'>Order Our Products <br/> That Fits to Your Needs</div>
        <div className='ob-subtitle'>We recognize that each customer has distinct tracking requirements. That's why we empower you to select our products that suits your tracker needs, ensuring that you only invest in the features that matter to you.</div>
       </div>
       <div className='car-img-wrap'>
       <img src={CarImg} alt="car" />
       </div>
      </div>
     </div>

      </div>
    </div>
  )
}

export default OrderBanner